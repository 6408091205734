import { GetStaticProps } from "next";
import Error from "next/error";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { sendGTMEvent } from "~/components/Tracking/GoogleTagManager";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { serverSideTranslations } from "~/translations/serverSideTranslations";
import { ServerSideGeneratedI18nNamespace } from "~/translations/types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Custom404PageProps extends ServerSideGeneratedI18nNamespace {}

export const getStaticProps: GetStaticProps<Custom404PageProps> = async (
  context,
) => {
  const locale = (context.locale ?? context.defaultLocale) as AVAILABLE_LOCALES;

  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
};

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    sendGTMEvent("error_404", {
      page_path: router.asPath,
      page_title: "404 - Page Not Found",
      page_location: window.location.href,
    });
  }, [router.asPath]);

  return <Error statusCode={404} />;
}
